.form_questionnaire {
  max-width: 750px;
  margin: 0 auto;
  .field {
    margin: 0 0 20px; }
  .form {
    &__btns {
      margin: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        margin: 0 15px; } } } }
