.services-missions-1 {
  padding: 93px 0 34px;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  @include m {
    padding-top: 77px;
    padding-bottom: 45px; }
  &__cols {
    display: flex;
    align-items: flex-start;
    @include m {
      display: block; }
    &:first-child {
      border-bottom: 2px solid #e9eaeb;
      margin-bottom: 55px;
      padding-bottom: 57px;
      @include m {
        margin-bottom: 43px;
        padding-bottom: 22px; } } }
  &__col {
    width: 50%;
    @include m {
      width: auto; }
    &_1 {
      padding-right: 80px;
      @include m {
        margin-bottom: 26px;
        padding-right: 0;
        font-size: 20px;
        line-height: normal; } }
    &_2 {
      padding-left: 10px;
      @include m {
        padding-left: 0;
        font-size: 20px;
        line-height: normal; } } }
  &__title {
    color: #2a2d84;
    font-size: 20px;
    line-height: 35px;
    @include m {
      font-size: 30px;
      line-height: 45px; } }
  p {
    margin: 24px 0;
    @include m {
      margin: 30px 0; }
    &:first-child {
      margin-top: 0;
      padding-top: 5px; } }
  ul {
    margin: 21px 0;
    li {
      margin: 15px 0 0;
      padding: 0 0 0 20px;
      position: relative;
      @include m {
        margin-top: 26px; }
      &:first-child {
        margin-top: 0; }
      &:before {
        content: '';
        border-radius: 50%;
        background-color: #6a6ca9;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 8px; } } }
  &__cite {
    margin: 16px 0 0;
    padding: 0 0 0 84px;
    color: #000;
    font-size: 16px;
    line-height: normal;
    position: relative;
    @include m {
      margin-top: 42px;
      padding-right: 150px;
      font-size: 20px; }
    &:before {
      content: '';
      background-color: #e9eaeb;
      height: 2px;
      width: 65px;
      position: absolute;
      left: 0;
      top: 9px; } }
  &__partner {
    border-top: 2px solid #e9eaeb;
    margin: 66px 0 0;
    padding: 51px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m {
      margin-top: 56px;
      padding-top: 39px;
      padding-bottom: 0; } }
  &__partner-text {
    font-size: 20px;
    line-height: normal;
    color: #2a2d84; }
  &__partner-logo {
    display: flex;
    align-items: center;
    img {
      display: block; }
    img + img {
      margin-left: 54px;
      @include m {
        margin-left: 31px; } } } }

.services-missions-2 {
  background-color: #2a2d84;
  padding: 91px 0 101px;
  @include m {
    padding-top: 77px;
    padding-bottom: 85px; }
  &__title {
    color: #f5d383;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 50px;
      font-size: 30px; } }
  &__list {
    margin: 55px 0 0;
    display: flex;
    justify-content: space-between;
    @include m {
      margin-top: 26px;
      display: block; } }
  &__item {
    width: 32%;
    @include m {
      width: auto;
      min-height: 63px;
      padding-left: 89px;
      position: relative; } }
  &__item + &__item {
    @include m {
      margin-top: 47px; } }
  &__icon {
    background-color: #1e2072;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    padding: 17px;
    box-sizing: content-box;
    @include m {
      position: absolute;
      left: 0;
      top: 0; }
    img {
      vertical-align: top; } }
  &__text {
    margin: 21px 0 0;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    @include m {
      margin-top: 0;
      font-size: 20px;
      line-height: normal; } }
  ul {
    margin: 5px 0;
    li {
      margin: 5px 0 0;
      padding: 0 0 0 14px;
      position: relative;
      @include m {
        margin-top: 6px; }
      &:first-child {
        margin-top: 0; }
      &:before {
        content: '';
        border-radius: 50%;
        background-color: #6a6ca9;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 8px;
        @include m {
          top: 10px; } } } } }

.services-missions-3 {
  background-color: #fcf2da;
  padding: 91px 0 95px;
  @include m {
    padding-top: 76px;
    padding-bottom: 85px; }
  &__title {
    margin: 0 0 5px;
    color: #2a2d84;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 0;
      font-size: 30px; } }
  &__list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap; }
  &__item {
    width: 50%;
    padding: 21px 80px 0 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: normal;
    position: relative;
    @include m {
      width: 100%;
      padding-top: 26px;
      padding-right: 0;
      font-size: 20px;
      &:nth-child(2n) {
        order: 1; } }
    &:nth-child(n+3) {
      border-top: 2px solid rgba(40, 47, 57, 0.1);
      margin-top: 24px;
      @include m {
        border-top: none;
        margin-top: 0; } }
    &:before {
      content: '';
      border-radius: 50%;
      background-color: #6a6ca9;
      width: 5px;
      height: 5px;
      position: absolute;
      left: 0;
      top: 31px;
      @include m {
        top: 34px; } } } }

.services-missions-4 {
  background-color: #2a2d84;
  padding: 91px 0 81px;
  @include m {
    padding-top: 77px;
    padding-bottom: 74px; }
  &__title {
    margin: 0 0 54px;
    color: #f5d383;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 50px;
      font-size: 30px; } }
  &__cols {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include m {
      display: block; } }
  &__col {
    width: 49%;
    @include m {
      width: auto; } }
  &__item {
    min-height: 63px;
    padding: 0 0 0 83px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    @include m {
      font-size: 20px;
      line-height: normal; } }
  &__col_2 {
    @include m {
      margin-top: 18px; } }
  &__item + &__item {
    margin-top: 14px;
    @include m {
      margin-top: 18px; } }
  &__icon {
    background-color: #1e2072;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 15.5px;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    top: 0;
    img {
      vertical-align: top; } }
  &__make-order {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    margin: 103px 0 0;
    padding: 79px 0 0;
    @include m {
      margin-top: 93px;
      padding-top: 72px;
      text-align: center; }
    .btn {
      min-width: 166px;
      @include m {
        min-width: 290px; } } } }
