@mixin x {
  @media only screen and (max-width: "1334px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1199px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "749px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@include font(sf-compact-display-thin, sf-compact-display-thin);
@mixin ft {
  font-family: 'sf-compact-display-thin', serif; }

@include font(sf-compact-display-medium, sf-compact-display-medium);
@mixin fm {
  font-family: 'sf-compact-display-medium', serif; }

$black: #000;
$white: #fff;
$yellow: #f5d383;
$blue: #2a2d84;
$dark-blue: #232676;

body {
  min-width: 750px;
  @include fm;
  font-size: 16px;
  line-height: (24/16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  &.no-scroll {
    overflow: hidden; } }

a {
  text-decoration: none; }

button {
  background: none; }

.out {
  @include m {
    overflow: hidden; } }

.btn {
  padding: 0 20px;
  height: 48px;
  border-radius: 5px;
  @include fm;
  font-size: 16px;
  color: $black;
  transition: all .3s;
  @include m {
    min-width: 192px;
    height: 62px;
    border-radius: 7px;
    font-size: 20px; }
  &_yellow {
    background: $yellow;
    &:hover {
      background: lighten($yellow, 7); } }
  &_white {
    background: $white;
    &:hover {
      background: darken($white, 10); } }
  &_border {
    border: 2px solid rgba($white, .1);
    font-size: 14px;
    color: #eecf8f;
    &:hover {
      background: rgba($white, .1); } }
  &_blue {
    background: $blue;
    color: $white;
    &:hover {
      background: lighten($blue, 10); } } }

a.btn {
  display: flex;
  justify-content: center;
  align-items: center; }

.center {
  max-width: 1335px;
  margin: 0 auto;
  padding: 0 30px;
  @include m {
    padding: 0 80px; }
  &_sm {
    max-width: 1120px; } }

@import "../blocks/header/header.sass";
@import "../blocks/main/main.sass";

.title {
  text-align: center;
  @include ft;
  font-size: 40px;
  line-height: 1.2;
  &_yellow {
    color: $yellow; }
  &_white {
    color: $white; }
  &_blue {
    color: $blue; } }

@import "../blocks/features-desc/features-desc.sass";
@import "../blocks/icons-text-list/icons-text-list.sass";
@import "../blocks/marked-list/marked-list.sass";
@import "../blocks/number-list/number-list.sass";
@import "../blocks/page-section/page-section.sass";
@import "../blocks/round-icon/round-icon.sass";

@import "../blocks/company/company.sass";
@import "../blocks/solution/solution.sass";
@import "../blocks/join/join.sass";
@import "../blocks/ksa/ksa.sass";
@import "../blocks/ksa-business-flow/ksa-business-flow.sass";
@import "../blocks/ksa-services/ksa-services.sass";
@import "../blocks/tech/tech.sass";
@import "../blocks/partners/partners.sass";
@import "../blocks/footer/footer.sass";
@import "../blocks/form/form.sass";
@import "../blocks/home/home.sass";
@import "../blocks/stat/stat.sass";
@import "../blocks/team/team.sass";
@import "../blocks/popup/popup.sass";
@import "../blocks/social/social.sass";
@import "../blocks/page/page.sass";

@import "../blocks/services/services.sass";
@import "../blocks/services-ai/services-ai.sass";
@import "../blocks/services-missions/services-missions.sass";
@import "../blocks/services-legal/services-legal.sass";
@import "../blocks/services-consult/services-consult.sass";
@import "../blocks/services-scouting/services-scouting.sass";
