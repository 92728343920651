.ksa-business-flow {
  background: linear-gradient(180deg, #2A2D84 0%, #016938 100%);
  color: $white;
  &__inner {
    padding-top: 43px;
    padding-bottom: 43px;
    @include m {
      padding-bottom: 48px; } }
  &__title {
    margin-bottom: 50px;
    @include m {
      margin-bottom: 60px;
      font-size: 56px; } }
  &__img {
    width: 1041px;
    max-width: 100%;
    @include m {
      width: 666px;
      max-width: none;
      margin-left: -38px; } }
  &__img_en {
    content: url('../../img/ksa-business-flow_en.svg');
    @include m {
      content: url('../../img/ksa-business-flow_en-mobile.svg'); } }
  &__img_ru {
    content: url('../../img/ksa-business-flow_ru.svg');
    @include m {
      content: url('../../img/ksa-business-flow_ru-mobile.svg'); } }
  &__caption {
    margin: 51px 0 0;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
    @include m {
      font-size: 32px; } }
  &__desc {
    margin: 74px 0 0;
    display: flex;
    justify-content: space-between;
    @include m {
      display: block; } }
  &__text {
    width: 41%;
    font-size: 18px;
    line-height: 1.44;
    font-weight: 500;
    @include m {
      width: auto;
      font-size: 24px;
      line-height: 2.16; } }
  &__list {
    width: 47.5%;
    @include m {
      width: auto;
      margin-top: 60px; } } }
