.company_tech {
  .company {
    &__inner {
      padding-top: 85px;
      padding-bottom: 95px;
      @include d {
        padding-top: 80px;
        padding-bottom: 80px; }
      @include m {
        padding-top: 80px;
        padding-bottom: 65px; } }
    &__row {
      align-items: center;
      margin: 0 -10px;
      @include t {
        margin: 0; } }
    &__col {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 0 10px;
      @include t {
        flex: 0 0 100%;
        width: 100%;
        margin: 0; } }
    &__col:first-child {
      @include t {
        margin-bottom: 65px; } }
    &__col:nth-child(2) {
      padding: 0 80px 0 65px;
      @include d {
        padding: 0 30px; }
      @include t {
        padding: 0; }
      @include m {
        padding: 0 40px; } } }

  .company__list_icon {
    margin-bottom: 55px;
    @include t {
      margin-bottom: 60px; }
    @include m {
      margin-bottom: 75px; } } }
