.services-legal-1 {
  margin-top: 93px;
  margin-bottom: 81px;
  @include m {
    margin-top: 77px;
    margin-bottom: 74px; }
  &__title {
    margin: 0 0 41px;
    font-size: 20px;
    line-height: 35px;
    color: #2a2d84;
    @include m {
      margin-bottom: 48px;
      font-size: 30px;
      line-height: 45px; } }
  &__columns {
    display: flex;
    @include m {
      display: block; } }
  &__columns_2 &__col {
    width: 50%; }
  &__columns &__col {
    @include m {
      width: auto; } }
  &__v-list {
    @include m {
      &:not(:first-child) {
        margin-top: 20px; } }
    &:not(:last-child) {
      padding-right: 20px; } }
  &__item {
    color: #000;
    font-size: 16px;
    line-height: normal;
    min-height: 63px;
    display: flex;
    align-items: center;
    @include m {
      font-size: 20px; } }
  &__item + &__item {
    margin-top: 15px;
    @include m {
      margin-top: 20px; } }
  &__icon {
    width: 63px;
    height: 63px;
    margin: 0 20px 0 0;
    background-color: #f8ecd2;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 63px;
    color: #232676;
    flex-shrink: 0;
    & img {
      display: inline-block;
      vertical-align: -6px; } }
  &__partner {
    border-top: 2px solid #e9eaeb;
    margin: 91px 0 0;
    padding: 51px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m {
      margin-top: 60px;
      padding-top: 45px; } }
  &__partner-text {
    font-size: 20px;
    line-height: normal;
    color: #2a2d84; }
  &__partner-logo {
    display: flex;
    align-items: center;
    img {
      display: block;
      &:first-child {
        @include m {
          width: 255px; } }
      &:last-child {
        @include m {
          width: 120px; } } }
    img + img {
      margin-left: 54px;
      @include m {
        margin-left: 31px; } } }
  &__make-order {
    border-top: 2px solid #e9eaeb;
    margin: 54px 0 0;
    padding: 79px 0 0;
    @include m {
      margin-top: 49px;
      padding-top: 72px;
      text-align: center; }
    .btn {
      min-width: 166px;
      @include m {
        min-width: 290px; } } } }
