.page-section_business_goals {
  padding-top: 50px;
  padding-bottom: 83px;

  .page-section__lists {
    display: flex;
    @include m {
      display: block; }
    .marked-list {
      flex-grow: 1;
      &:first-child {
        margin-right: 40px;
        @include m {
          margin-right: 0; } } } } }
