.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  min-width: 750px;
  padding: 20px;
  background: rgba(#141428, 0.8);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  z-index: 999;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  @include m {
    padding: 0; }
  &.visible {
    pointer-events: all;
    visibility: visible;
    opacity: 1; }
  &.animation &__wrap {
    opacity: 1;
    transform: scale(1); }
  &__wrap {
    position: relative;
    max-width: 844px;
    width: 100%;
    padding: 75px 160px 80px;
    margin: auto;
    opacity: 0;
    transform: scale(.8);
    transition: opacity .4s, transform .4s;
    border-radius: 10px;
    background: $white;
    @include d {
      padding: 50px 120px; }
    @include t {
      padding: 50px 80px; }
    @include m {
      min-height: 100vh;
      padding: 80px 80px 100px;
      border-radius: 0; } }
  &__title {
    margin-bottom: 50px;
    color: #453d2c; }
  &__item:not(:last-child) {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 32px; } }
  &__top {
    margin-bottom: 35px;
    @include m {
      margin-bottom: 40px; } }
  &__row {
    display: flex;
    margin: 0 -15px;
    @include m {
      display: block;
      margin: 0; }
    &_sm {
      margin: 0 -8px;
      @include m {
        display: flex;
        margin: 0 -20px; } } }
  &__col {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 0 15px;
    @include m {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:not(:last-child) {
      @include m {
        margin-bottom: 32px; } } }
  &__row_sm &__col {
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px;
    @include m {
      flex: 0 0 calc(50% - 40px);
      width: calc(50% - 40px);
      margin: 0 20px; } }
  &__body {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 35px; } }
  &__category {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: (20/14);
    color: $black;
    @include m {
      margin-bottom: 20px;
      font-size: 24px; } }
  &__variants {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
  &__checkbox {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 0 15px 10px; }
  &__radio {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 0 15px 10px; }
  &__bot {
    margin-bottom: 55px;
    @include m {
      margin-bottom: 90px; } }
  &__btns {
    text-align: center; }
  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    border: 2px solid #eecf8f;
    border-radius: 50%;
    font-size: 0;
    transition: all .3s;
    @include m {
      border: none;
      top: 55px;
      right: 40px;
      width: 27px;
      height: 27px; }
    .icon {
      font-size: 16px;
      fill: #eecf8f;
      transition: all .3s;
      @include m {
        font-size: 27px; } }
    &:hover {
      background: #EECF8F;
      @include m {
        background: $white; }
      .icon {
        fill: $white;
        @include m {
          fill: #eecf8f; } } } } }
