.main_default {
  background: url('../../../img/bg-main.jpg') no-repeat 50% 50% / cover;
  .main {
    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 700px;
      padding: 220px 30px 150px; }
    &__wrap {
      text-align: center; }
    &__title {
      max-width: 760px;
      margin: 0 auto 30px;
      font-size: 40px;
      line-height: 1.2;
      color: $white; }
    &__link {
      display: inline-flex;
      align-items: center; }
    &__link:hover &__preview {
      background: lighten($yellow, 7); }
    &__preview {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin-right: 15px;
      background-color: $yellow;
      border-radius: 5px;
      font-size: 0;
      transition: all .3s;
      @include m {
        width: 58px;
        height: 58px;
        margin-right: 25px; } }
    &__pic {
      width: 20px;
      @include m {
        width: 24px; } }
    &__text {
      color: $white;
      @include m {
        font-size: 20px; } } } }
