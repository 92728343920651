.ksa-services {
  background: #016938;
  color: $white;
  &__inner {
    padding-top: 93px;
    padding-bottom: 75px;
    @include m {
      padding-top: 70px;
      padding-bottom: 84px; } }
  &__title {
    @include m {
      font-size: 56px; } }
  &__icons-list {
    margin: 108px 0 0;
    padding: 0 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    @include m {
      padding: 0;
      flex-wrap: wrap; } }
  &__icons-list-item {
    padding: 0 15px;
    color: $white;
    @include m {
      width: 50%;
      &:nth-child(n+3) {
        margin-top: 80px; } } }
  &__icons-list-item:hover {
    color: #d9d9d9; }
  &__icons-list-icon {
    height: 82px;
    font-size: 0;
    text-align: center;
    @include m {
      height: 164px; } }
  &__icons-list-img {
    @include m {
      transform: scale(2);
      transform-origin: top; } }
  &__icons-list-text {
    text-align: center;
    @include m {
      font-size: 34px;
      line-height: 1.2; } }
  &__links {
    margin: 80px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m {
      display: block;
      font-size: 32px;
      line-height: 1.2;
      text-align: center; } }
  &__links-text {
    @include m {
      display: block; } }
  &__links-btn {
    margin-left: 31px;
    @include m {
      margin-top: 31px;
      margin-left: 0;
      display: inline-block; } } }
