.ksa {
  background-color: #2A2D84;
  position: relative;
  &__picture {
    margin-left: -30px;
    overflow: hidden;
    position: absolute;
    top: 51px;
    left: 50%;
    right: 0;
    z-index: 0;
    @include m {
      margin-left: -230px; } }
  &__img {
    width: 705px;
    height: 705px;
    @include d {
      width: 635px;
      height: 635px; }
    @include t {
      width: 530px;
      height: 530px; }
    @include m {
      width: 720px;
      height: 720px; } }
  &__inner {
    padding-top: 72px;
    padding-bottom: 80px;
    position: relative;
    z-index: 2;
    @include m {
      padding-top: 56px !important;
      padding-bottom: 100px !important; } }
  &__caption {
    margin: 0 0 186px;
    @include ft;
    font-size: 42px;
    line-height: 1.2;
    color: #fff;
    @include d {
      margin-bottom: 166px; }
    @include m {
      margin-bottom: 680px;
      font-size: 48px; } }
  &__gth {
    width: 530px;
    margin-bottom: 222px;
    @include d {
      margin-bottom: 202px; }
    @include t {
      margin-bottom: 182px; }
    @include m {
      border-right: 20px solid #EDCE8F;
      width: auto;
      margin-bottom: 95px;
      padding: 57px 50px 70px 0;
      position: relative;
      &:before, &:after {
        content: '';
        background-color: #EDCE8F;
        height: 20px;
        position: absolute;
        right: -20px; }
      &:before {
        width: 104px;
        top: 0; }
      &:after {
        left: 0;
        bottom: 0; } } }
  &__gth-logo {
    margin-bottom: 24px;
    font-size: 0;
    @include m {
      margin-bottom: 50px; } }
  &__gth-logo-img {
    height: 56px;
    vertical-align: top;
    @include m {
      height: 112px; } }
  &__gth-text {
    color: #fff;
    @include m {
      font-size: 24px;
      line-height: 2; } }
  &__spacer {
    margin-bottom: 79px;
    @include m {
      margin-bottom: 59px; } }
  &__hr {
    border-bottom: solid #EDCE8F 10px;
    height: 21px;
    margin-bottom: 36px;
    box-sizing: content-box;
    position: relative;
    @include m {
      display: none; }
    &:before, &:after {
      content: '';
      background-color: #EDCE8F;
      width: 10px;
      position: absolute;
      top: 0;
      bottom: 0; }
    &:before {
      left: 0; }
    &:after {
      right: 0; } }
  &__hr-logo {
    font-size: 0;
    text-align: center; }
  &__hr-logo-img {
    height: 78px;
    vertical-align: top;
    @include m {
      height: 156px; } }
  &__desc {
    display: flex;
    color: #fff;
    @include m {
      display: block;
      font-size: 24px;
      line-height: 2; } }
  &__desc-item {
    flex-grow: 1; }
  &__desc-item:first-child {
    padding-right: 18px;
    @include m {
      padding-right: 0; } }
  &__desc-item:last-child {
    padding-left: 18px;
    @include m {
      margin-top: 37px;
      padding-left: 0; } }
  .ksa__more {
    margin: 50px 0 0;
    display: flex;
    justify-content: center; } }
