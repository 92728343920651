.home {
  padding-top: 180px;
  background: url('../../img/bg-main.jpg') no-repeat 50% 0% / cover;
  @include d {
    padding-top: 136px; }
  @include m {
    padding-top: 155px; }
  &__center {
    @include m {
      padding: 0 40px; } }
  &__wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 135px;
    padding: 20px 110px;
    border-top: 1px solid rgba($white, .2);
    @include m {
      border-top: 3px solid #1c1e6a; } }
  &__title {
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
    color: $white;
    br {
      display: none;
      @include m {
        display: block; } } }
  &__link {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center; }
  &__preview {
    margin-right: 15px;
    font-size: 0; }
  &__text {
    border-bottom: 1px solid $white;
    font-size: 18px;
    line-height: 1.2;
    color: $white;
    transition: border-color .3s;
    &:hover {
      border-color: transparent; } }
  &__nav {
    text-align: center;
    a {
      color: #fff;
      text-decoration: underline;
      padding: 0 3px;
      margin: 0 4px;
      white-space: nowrap;
      &:hover {
        text-decoration: none; }
      &:not([href]) {
        font-weight: bold;
        text-decoration: none;
        cursor: default; } } } }
