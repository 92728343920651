.tech {
  padding: 83px 0 65px;
  background: url('../../img/bg-tech.jpg') no-repeat 50% 50% / cover;
  @include m {
    padding: 80px 0 90px; }
  &__center {
    @include m {
      padding: 0 60px; } }
  &__title {
    margin-bottom: 5px; }
  &__info {
    margin-bottom: 73px;
    text-align: center;
    @include ft;
    font-size: 18px;
    color: $white;
    @include d {
      margin-bottom: 60px; } }
  &__icons {
    display: flex;
    justify-content: space-around;
    margin: 0 0 100px;
    @include d {
      margin-bottom: 80px; }
    @include t {
      margin-bottom: 70px; }
    @include m {
      margin-bottom: 60px; } }
  &__icons-item {
    padding: 0 10px;
    text-align: center; }
  &__icons-img {
    height: 100px;
    vertical-align: top;
    @include d {
      height: 80px; }
    @include t {
      height: 70px; }
    @include m {
      height: 60px; } }
  &__icons-text {
    margin: 20px 0 0;
    @include fm;
    font-size: 22px;
    line-height: 1.2;
    color: #fff;
    @include d {
      font-size: 20px; }
    @include t {
      font-size: 18px; }
    @include m {
      font-size: 16px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include m {
      margin-bottom: 25px; } }
  &__item {
    position: relative;
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    height: 245px;
    margin: 0 10px 20px;
    @include t {
      flex: 0 0 calc(33.33% - 20px);
      width: calc(33.33% - 20px); }
    @include m {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      height: 282px; }
    &:hover {
      z-index: 2; }
    // &_empty
    //   border-radius: 5px
    //   background: rgba($white, .3)
    //   &:nth-last-child(1),
    //   &:nth-last-child(2),
    //   &:nth-last-child(3)
    //     +t
    //       display: none
    &:nth-child(n+7) {
      @include m {
        display: none; } } }
  &__item:hover &__in {
    box-shadow: 0 0 24px 1px rgba(40, 45, 56, 0.5); }
  &__item:hover &__text {
    height: auto;
    min-height: 120px;
    @include m {
      min-height: 168px; } }
  &__wrap {
    border-radius: 5px;
    background: $white; }
  &__preview {
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px 30px 5px;
    border-radius: 5px 5px 0 0;
    background: $white;
    @include d {
      padding: 10px 20px 5px; }
    @include m {
      padding: 10px 25px 5px; }
    &:before {
      content: "";
      position: absolute;
      left: 30px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: rgba($black, .1); }
    &_big {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; } }
  &__pic {
    max-width: 100%; }
  &__company {
    margin-top: 5px;
    font-size: 14px;
    color: #8b27c9;
    @include d {
      white-space: nowrap;
      font-size: 12px; }
    @include m {
      font-size: 14px; } }
  &__in {
    padding: 15px 27px 27px 30px;
    border-radius: 0 0 5px 5px;
    @include d {
      padding: 15px 20px 27px 20px; }
    @include m {
      padding: 15px 25px 20px 25px; } }
  &__text {
    height: 120px;
    font-size: 13px;
    line-height: (21/13);
    @include m {
      height: 168px;
      font-size: 16px;
      line-height: (24/16); }
    p:not(:last-child) {
      margin-bottom: 18px; } }
  &__btns {
    display: none;
    text-align: center;
    @include m {
      display: block; } }
  &.expanded &__item:nth-child(n+7) {
    @include m {
      display: block; } }
  &.expanded &__btns {
    @include m {
      display: none; } } }
