.company {
  @include m {
    font-size: 24px; }

  &__inner {
    @include m {
      padding: 0 40px; } }

  &__title {
    margin-bottom: 60px;
    @include d {
      margin-bottom: 40px; } }

  &__row {
    display: flex;
    @include t {
      display: block; } }

  &__preview {
    font-size: 0;
    @include t {
      text-align: center; } }

  &__pic {
    width: 100%;
    vertical-align: top;
    @include t {
      width: 600px; }
    @include m {
      width: 100%; } }

  &__caption {
    @include ft;
    font-size: 1.875em;
    line-height: 1.4;
    @include m {
      margin-bottom: 20px;
      font-size: 1.25em; } }
  &__caption_bold {
    font-family: inherit;
    font-size: 1.25em;
    color: $blue;
    margin-bottom: 15px;
    @include m {
      margin-bottom: 25px; } }

  &__paragraph + &__paragraph {
    margin-top: 25px;
    @include t {
      margin-top: 15px; }
    @include m {
      margin-top: 30px; } }

  &__list &__item {
    padding: 24px 0;
    font-size: 1em;
    @include m {
      padding: 22px 0;
      font-size: 0.83em;
      line-height: 1.5em; }
    &:not(:last-child) {
      border-bottom: 1px solid rgba($black, .1); } }

  &__list_marker &__item {
    position: relative;
    padding: 15px 0 15px 15px;
    @include m {
      padding: 20px 0 20px 20px; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      margin-top: -3px;
      border-radius: 50%;
      background: $yellow; } }

  &__list_icon &__item {
    padding: 0;
    display: flex;
    color: $blue;
    align-items: center;
    @include m {
      padding: 0;
      font-size: 1em; }
    &:not(:last-child) {
      border-bottom: none;
      margin-bottom: 20px; } }
  &__list_icon &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 63px;
    width: 63px;
    height: 63px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: rgba(#e9e9f3, .5);
    @include m {
      flex: 0 0 95px;
      width: 95px;
      height: 95px;
      margin-right: 30px; } }
  &__list_icon &__item:first-child &__img {
    @include m {
      width: 27px; } }
  &__list_icon &__item:nth-child(2) &__img {
    @include m {
      width: 33px; } }
  &__list_icon &__item-text {
    font-size: 1.125em;
    line-height: 1.3;
    color: $blue;
    @include m {
      font-size: 1em; } } }

@import "./_main/company_main.sass";
@import "./_tech/company_tech.sass";
