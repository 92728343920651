.page-section_business_cost {
  background: $white;
  padding-top: 0;
  padding-bottom: 0;
  color: #2B3082;
  position: relative;
  &:before {
    content: '';
    background: #2B3082;
    position: absolute;
    left: 0;
    right: 50%;
    top: 0;
    bottom: 0;
    @include m {
      right: 0;
      bottom: 75%; } } }

.page-section_business_cost {
  .page-section {
    &__cols {
      display: flex;
      position: relative;
      @include m {
        display: block; } }
    &__col {
      padding: 37px 0 76px;
      @include m {
        padding: 76px 0;
        &:first-child {
          padding-top: 0; }
        &:last-child {
          padding-bottom: 0; } } }
    &__col_1 {
      background: #2B3082;
      padding-right: 37px;
      color: $white;
      flex-shrink: 3;
      @include m {
        padding-right: 0;
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          background: #2B3082;
          height: 100%;
          position: absolute;
          left: -80px;
          right: -80px;
          top: 0;
          z-index: -1; } } }
    &__col_2 {
      background: $white;
      padding-left: 37px;
      @include m {
        padding-left: 0; } }

    &__caption {
      margin: 0 0 37px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      @include m {
        font-size: 30px; } }
    &__col_1 .page-section__caption {
      color: #EDCE8F; } } }
