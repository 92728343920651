.page-section_ai_desc {
  padding-top: 51px;
  padding-bottom: 66px;
  font-size: 16px;
  line-height: 1.2;
  @include m {
    font-size: 24px; }

  .page-section {
    &__caption {
      margin: 0 0 55px;
      @include fm;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: #EDCE8F;
      @include m {
        font-size: 30px;
        margin-bottom: 68px; } }

    &__hr {
      background: $white;
      height: 2px;
      margin: 60px 0 61px; }

    &__advantages {
      margin-top: 30px; } }

  .number-list {
    max-width: 600px; }
  .number-list__icon {
    margin-right: 38px; } }
