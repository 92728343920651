.partners {
  padding: 75px 0;
  @include t {
    padding: 70px 0; }
  @include m {
    padding: 80px 0; }
  &__title {
    margin-bottom: 65px;
    color: #453d2c;
    @include t {
      margin-bottom: 50px; } }
  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
  &__cell {
    flex-basis: 16%;
    font-size: 0;
    text-align: center; }
  &__logo {
    max-width: 160px;
    max-height: 160px;
    padding: 20px;
    vertical-align: top; } }
