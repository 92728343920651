.social {
  display: flex;
  &__link {
    font-size: 0;
    .icon {
      fill: #eecf8f;
      transition: fill .3s; }
    &:hover .icon {
      fill: $white; } }
  &_sm .icon {
    font-size: 16px;
    @include m {
      font-size: 24px; } }
  &_sm &__link:not(:last-child) {
    margin-right: 23px; }
  &_m .icon {
    font-size: 23px; }
  &_m &__link:not(:last-child) {
    margin-right: 40px; }
  &_big .icon {
    font-size: 40px; }
  &_big &__link:not(:last-child) {
    margin-right: 60px; } }
