.solution {
  padding: 80px 0 105px;
  background: $blue;
  @include t {
    padding: 80px 0; }
  &__center {
    @include m {
      padding: 0 75px; } }
  &__title {
    margin-bottom: 55px;
    @include m {
      margin-bottom: 60px; } }
  &__row {
    display: flex;
    margin: 0 -10px;
    @include t {
      display: block;
      margin: 0; }
    &:first-child {
      margin-bottom: 70px;
      padding-bottom: 70px;
      border-bottom: 1px solid $dark-blue;
      @include t {
        margin-bottom: 60px;
        padding-bottom: 60px; }
      @include m {
        border-width: 2px; } } }
  &__col {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 10px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:not(:last-child) {
      @include t {
        margin-bottom: 20px; }
      @include m {
        margin-bottom: 25px; } } }
  &__text {
    color: $white;
    @include m {
      font-size: 24px; }
    p:not(:last-child) {
      margin-bottom: 25px;
      @include t {
        margin-bottom: 20px; }
      @include m {
        margin-bottom: 25px; } } }
  &__item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 25px; } } }
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 63px;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background-color: #232676;
    font-size: 0;
    @include m {
      flex: 0 0 95px;
      width: 95px;
      height: 95px; } }
  &__pic {
    width: 20px;
    @include m {
      width: 32px; } }
  &__box {
    flex: 0 0 calc(100% - 63px);
    width: calc(100% - 63px);
    padding-left: 20px;
    @include m {
      flex: 0 0 calc(100% - 95px);
      width: calc(100% - 95px);
      padding-left: 25px; } }
  &__category {
    margin-bottom: 5px;
    font-size: 18px;
    color: $yellow;
    @include m {
      font-size: 27px; } }
  &__info {
    font-size: 14px;
    line-height: (20/14);
    color: $white;
    @include m {
      font-size: 20px; }
    br {
      @include t {
        display: none; } } } }
