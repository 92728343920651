.team {
  padding: 80px 0 20px;
  @include m {
    padding: 90px 0 30px; }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include m {
      margin: 0 -20px; } }
  &__item {
    flex: 0 0 (100%/3);
    width: (100%/3);
    margin: 0 0 55px;
    padding: 0 10px;
    padding-bottom: 55px;
    border-bottom: 1px solid rgba($black, .1);
    @include m {
      flex: 0 0 50%;
      width: 50%;
      margin-bottom: 45px;
      padding-bottom: 45px; } }
  &__ava {
    position: relative;
    max-width: 275px;
    margin-bottom: 25px;
    font-size: 0;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 140px;
      border-radius: 0 0 5px 5px;
      background-image: linear-gradient(to top, rgba(#2b2e84, .5), rgba($white, 0));
      @include d {
        height: 110px; }
      @include t {
        height: 90px;
        background-image: linear-gradient(to top, rgba(#2b2e84, .3), rgba($white, 0)); } } }
  &__pic {
    width: 100%;
    border-radius: 5px; }
  &__man {
    font-size: 26px;
    line-height: 1.3;
    color: $yellow; }
  &__post {
    font-size: 18px;
    color: $blue; }
  &__text {
    margin-top: 13px;
    padding-right: 4px;
    font-size: 14px;
    line-height: (22/14);
    @include m {
      font-size: 20px; }
    br {
      @include m {
        display: none; } }
    p:not(:last-child) {
      margin-bottom: 8px;
      @include m {
        margin-bottom: 0; } }
    &_ellipsis {
      padding-bottom: 2.14em;
      position: relative; }
    &:not(&_ellipsis) {
      height: auto !important; }
    &_ellipsis:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2.14em;
      height: 3em;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 100%); } }
  &__text_ellipsis &__text-inner {
    height: 100%;
    overflow: hidden; }
  &__text-toggle {
    display: inline-block;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 0 8px;
    cursor: pointer;
    left: 0;
    text-align: center; }
  &__text_ellipsis &__text-toggle {
    position: absolute;
    bottom: 0; }
  &__desc {
    margin-top: 13px; }
  &__desc &__text {
    margin: 0; }
  &__list &__text {
    position: relative;
    padding-left: 20px;
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 15px;
      height: 2px;
      background: $black; } }
  &__title {
    margin-bottom: 50px;
    line-height: 1; }
  &__experts &__item {
    margin-bottom: 60px;
    padding-bottom: 0;
    border: none; } }
