.features-desc {
  &__text {
    color: #2a2d84;
    font-size: 20px;
    line-height: 35px;
    margin: 0 0 60px;
    @include m {
      margin-bottom: 48px;
      font-size: 30px;
      line-height: 45px; } }
  &__hr {
    background: #e9eaeb;
    height: 2px; }
  &__caption {
    margin: 0 0 55px;
    padding: 61px 0 0;
    color: #2a2d84;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 52px;
      padding-top: 47px;
      font-size: 30px;
      line-height: normal; } }
  &__list + &__list-title {
    margin-top: 61px;
    @include m {
      margin-top: 47px; } } }
