.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 65px 0 30px;
  @include d {
    padding: 40px 0 30px; }
  @include t {
    padding: 25px 0; }
  @include m {
    padding: 40px 0; }
  &__center {
    display: flex;
    align-items: center;
    @include d {
      position: relative; }
    @include m {
      padding: 0 40px;
      position: static; } }
  &__logo {
    margin-right: auto; }
  &__wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 4.7%;
    @include m {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      flex-direction: column;
      align-items: stretch;
      min-height: 100vh;
      margin: 0;
      padding: 50px 40px 50px 75px;
      background: $blue;
      transform: translateX(-100%);
      opacity: 0;
      transition: all .4s;
      &.visible {
        transform: translateX(0);
        opacity: 1; } } }
  &__nav {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    @include d {
      width: 100%;
      justify-content: center;
      position: absolute;
      top: 60px;
      left: 0; }
    @include m {
      width: auto;
      flex-direction: column;
      border-bottom: 3px solid #1C1E6A;
      margin: 0 0 100px 0;
      padding-bottom: 100px;
      position: static; } }
  &__link {
    font-size: 18px;
    color: $white;
    transition: all .3s;
    @include m {
      font-size: 40px;
      line-height: 1.2; }
    &:hover {
      color: darken($white, 20); }
    &:not(:last-child) {
      margin-right: 2%;
      @include m {
        margin: 0 0 35px 0; } } }
  &__language {
    display: flex;
    margin-left: 60px;
    @include t {
      margin-left: 35px; }
    @include m {
      order: -1;
      margin: 0 0 100px 0; } }
  &__item {
    position: relative;
    font-size: 18px;
    color: $white;
    transition: all .3s;
    @include m {
      font-size: 30px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      height: 2px;
      background: $yellow;
      visibility: hidden;
      opacity: 0;
      transition: all .3s; }
    &:hover {
      color: darken($white, 20); }
    &.active:before {
      visibility: visible;
      opacity: 1; }
    &:not(:last-child) {
      margin-right: 20px; } }
  &__btn {
    @include t {
      min-width: 120px; }
    @include m {
      min-width: 192px;
      height: 62px; } }
  &__control {
    display: none;
    @include m {
      display: block; } }
  &__top {
    margin-bottom: 50px; }
  &__mail {
    font-size: 40px;
    line-height: 1.2;
    text-decoration: underline;
    color: #ebce93; } }

.burger {
  display: none;
  position: relative;
  z-index: 20;
  width: 34px;
  height: 26px;
  margin-left: 35px;
  text-align: center;
  @include m {
    display: inline-block; }
  &__lines {
    position: relative;
    display: block;
    width: 34px;
    height: 4px;
    margin: 11px auto 0;
    background: $white;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      height: 4px;
      background: $white;
      transition: all .2s; }
    &:before {
      top: -11px;
      width: 34px; }
    &:after {
      bottom: -11px;
      width: 24px; } }
  &.active &__lines {
    top: 0;
    transform: rotate(-44deg); }
  &.active &__lines:before {
    top: 0;
    transform: rotate(86deg); }
  &.active &__lines:after {
    top: 0;
    width: 34px;
    opacity: 0;
    transform: rotate(180deg); } }

.logo {
  &__preview {
    font-size: 0; }
  &__pic {
    width: 165px;
    @include t {
      width: 130px; }
    @include m {
      width: 198px; } } }
