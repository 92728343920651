.services * {
  transition: all .3s linear; }

.services-1 {
  padding: 93px 0 100px;
  @include m {
    padding-top: 77px;
    padding-bottom: 82px; }
  &__inner {
    display: flex;
    align-items: flex-start;
    @include m {
      display: block; } }
  &__col {
    width: 50%;
    @include m {
      width: auto; }
    &_1 {
      padding-right: 80px;
      color: #2a2d84;
      font-size: 20px;
      line-height: 35px;
      @include m {
        margin-bottom: 36px;
        padding-right: 0;
        font-size: 30px;
        line-height: 45px; } }
    &_2 {
      padding-left: 10px;
      color: #000;
      font-size: 16px;
      line-height: 24px;
      @include m {
        padding-left: 0;
        font-size: 20px;
        line-height: 30px; } } }
  p {
    margin: 20px 0;
    &:first-child {
      margin-top: 0; } }
  ul {
    li {
      margin: 6px 0 0;
      padding: 0 0 0 24px;
      position: relative;
      @include m {
        margin-top: 0; }
      &:first-child {
        margin-top: 0; }
      &:before {
        content: '';
        border-radius: 50%;
        background-color: #f5d383;
        width: 9px;
        height: 9px;
        position: absolute;
        left: 0;
        top: 8px; } } } }

.services-2 {
  background-color: #2d3087;
  padding: 144px 0 146px;
  @include m {
    padding-top: 87px;
    padding-bottom: 61px; }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    @include m {
      flex-wrap: wrap;
      justify-content: center; } }
  &__item {
    max-width: 196px;
    text-align: center;
    @include m {
      width: 30%;
      margin-bottom: 49px;
      padding-left: 1%;
      padding-right: 1%;
      &:nth-child(n+4) {
        margin-bottom: 0; } } }
  &__item:hover &__text {
    text-decoration: underline; }
  &__icon {
    width: 74px;
    height: 74px;
    display: inline-block;
    vertical-align: top; }
  &__text {
    margin: 35px 0 0;
    color: #fff;
    font-size: 18px;
    line-height: normal; } }

.services-3 {
  background: #fcf2da url("/img/services-3_bg.png") 50% 50% no-repeat;
  background-size: cover;
  padding: 78px 0 80px;
  @include m {
    padding-top: 76px;
    padding-bottom: 85px; }
  &__title {
    margin: 0 0 54px;
    color: #232676;
    font-size: 30px;
    line-height: normal;
    @include m {
      margin-bottom: 52px; } }
  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include m {
      display: block; } }
  &__item {
    width: 30%;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    @include m {
      margin-top: 55px;
      width: auto;
      font-size: 20px;
      line-height: normal;
      &:first-child {
        margin-top: 0; } } }
  &__icon {
    border-radius: 50%;
    background-color: #fff;
    width: 63px;
    height: 63px;
    padding: 17px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    @include m {
      width: 126px;
      height: 126px;
      padding: 34px;
      position: absolute; } }
  &__header {
    margin: 15px 0 33px;
    color: #232676;
    font-size: 18px;
    line-height: normal;
    @include m {
      padding-left: 157px;
      font-size: 24px;
      line-height: 126px; } }
  ul {
    li {
      margin: 10px 0 0;
      padding: 0 0 0 15px;
      position: relative;
      @include m {
        margin-top: 16px; }
      &:first-child {
        margin-top: 0; }
      &:before {
        content: '';
        border-radius: 50%;
        background-color: #6a6ca9;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 8px; } } } }

.services-4 {
  background-color: #1e2072;
  padding: 68px 0 94px;
  @include m {
    padding-top: 171px;
    padding-bottom: 85px; }
  &__inner {
    padding: 0 0 0 287px;
    position: relative;
    @include m {
      padding-left: 0; } }
  &__item {
    margin: 25px 0 0;
    padding: 0 0 0 82px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    @include m {
      margin-top: 27px;
      font-size: 20px;
      line-height: normal; }
    &:first-child {
      margin-top: 0; }
    &:before {
      content: '';
      background: rgba(255, 255, 255, 0.2);
      width: 63px;
      height: 2px;
      position: absolute;
      left: 0;
      top: 11px; } }
  &__logo {
    position: absolute;
    left: 0;
    top: 7px;
    @include m {
      top: -108px; } } }
