.number-list {
  counter-reset: number;

  &__icon:before {
    counter-increment: number;
    content: counter(number); }

  &__item {
    font-size: 16px;
    line-height: 1.5;
    min-height: 63px;
    display: flex;
    align-items: center;
    @include m {
      min-height: 126px;
      font-size: 24px; } }

  &__item + &__item {
    margin-top: 15px;
    @include m {
      margin-top: 30px; } }
  &__icon {
    width: 63px;
    height: 63px;
    margin: 0 20px 0 0;
    background-color: #f8ecd2;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 63px;
    color: #232676;
    flex-shrink: 0;
    @include m {
      width: 126px;
      height: 126px;
      font-size: 30px;
      line-height: 126px; } } }
