.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.54em;
  height: 1em;
  fill: initial;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1.15em;
  height: 1em;
  fill: initial;
}
.icon-twitter {
  width: 1.22em;
  height: 1em;
  fill: initial;
}
