.services-consult-1 {
  margin-top: 93px;
  margin-bottom: 81px;
  @include m {
    margin-top: 77px;
    margin-bottom: 74px; }
  &__title {
    color: #2a2d84;
    font-size: 20px;
    line-height: 35px;
    margin: 0 0 60px;
    @include m {
      margin-bottom: 48px;
      font-size: 30px;
      line-height: 45px; } }
  &__list-title {
    border-top: 2px solid #e9eaeb;
    margin: 0 0 55px;
    padding: 61px 0 0;
    color: #2a2d84;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 52px;
      padding-top: 47px;
      font-size: 30px;
      line-height: normal; } }
  &__list + &__list-title {
    margin-top: 61px;
    @include m {
      margin-top: 47px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    @include m {
      display: block; } }
  &__item {
    width: 33%;
    padding-right: 20px;
    color: #000;
    font-size: 16px;
    line-height: normal;
    @include m {
      width: auto;
      padding-right: 0;
      font-size: 20px; }
    &:nth-child(3n) {
      padding-right: 0; }
    &:nth-child(n+4) {
      margin-top: 20px;
      @include m {
        margin-top: 0; } } }
  &__item + &__item {
    @include m {
      margin-top: 35px; } }
  &__icon {
    width: 63px;
    height: 63px;
    margin: 0 0 24px;
    background-color: #f8ecd2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m {
      margin-bottom: 23px; }
    & img {
      max-width: 35px;
      max-height: 35px; } }
  ul {
    margin: 10px 0;
    @include m {
      margin: 6px 0; }
    li {
      margin: 9px 0 0;
      padding: 0 0 0 15px;
      position: relative;
      @include m {
        margin-top: 6px; }
      &:first-child {
        margin-top: 0; }
      &:before {
        content: '';
        border-radius: 50%;
        background-color: #6a6ca9;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 8px; } } }
  &__partner {
    border-top: 2px solid #e9eaeb;
    margin: 56px 0 0;
    padding: 55px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m {
      margin-top: 55px;
      padding-top: 45px; } }
  &__partner-text {
    font-size: 20px;
    line-height: normal;
    color: #2a2d84; }
  &__make-order {
    border-top: 2px solid #e9eaeb;
    margin: 54px 0 0;
    padding: 79px 0 0;
    @include m {
      margin-top: 49px;
      padding-top: 72px;
      text-align: center; }
    .btn {
      min-width: 166px;
      @include m {
        min-width: 290px; } } } }
