.services-ai-1 {
  padding: 93px 0 70px;
  @include m {
    padding-top: 77px;
    padding-bottom: 85px; }
  &__cols {
    display: flex;
    align-items: flex-start;
    @include m {
      display: block; } }
  &__col {
    width: 50%;
    @include m {
      width: auto; }
    &_1 {
      padding-right: 80px;
      color: #2a2d84;
      font-size: 20px;
      line-height: 35px;
      @include m {
        margin-bottom: 36px;
        padding-right: 0;
        font-size: 30px;
        line-height: 45px; } }
    &_2 {
      padding-left: 10px;
      color: #000;
      font-size: 16px;
      line-height: 24px;
      @include m {
        padding-left: 0;
        font-size: 20px;
        line-height: 30px; } } }
  p {
    margin: 20px 0;
    @include m {
      margin: 30px 0; }
    &:first-child {
      margin-top: 0; } }
  &__list {
    border-top: 2px solid #e9eaeb;
    margin: 74px 0 0;
    padding: 60px 0 0;
    display: flex;
    justify-content: space-between;
    @include m {
      margin-top: 52px;
      padding-top: 40px;
      flex-wrap: wrap; } }
  &__item {
    width: 24%;
    @include m {
      width: 43%;
      &:nth-child(n+3) {
        margin-top: 36px; } } }
  &__icon {
    background-color: #f8ecd2;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    padding: 17px;
    box-sizing: content-box;
    img {
      vertical-align: top; } }
  &__text {
    margin: 22px 0 0;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    @include m {
      margin-top: 24px;
      font-size: 20px;
      line-height: normal; } } }

.services-ai-2 {
  background-color: #2a2d84;
  padding: 90px 0 81px;
  @include m {
    padding-top: 77px;
    padding-bottom: 74px; }
  &__title {
    margin: 0 0 39px;
    color: #f5d383;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 48px;
      font-size: 30px; } }
  &__cols {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include m {
      display: block; } }
  &__col {
    width: 49%;
    @include m {
      width: auto; } }
  &__item {
    min-height: 63px;
    padding: 0 0 0 79px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    @include m {
      font-size: 20px;
      line-height: normal; } }
  &__col_2 {
    @include m {
      margin-top: 30px; } }
  &__item + &__item {
    margin-top: 15px;
    @include m {
      margin-top: 30px; } }
  &__icon {
    background-color: #1e2072;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 15.5px;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    top: 0;
    img {
      vertical-align: top; } }
  &__make-order {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    margin: 62px 0 0;
    padding: 79px 0 0;
    @include m {
      margin-top: 90px;
      padding-top: 72px;
      text-align: center; }
    .btn {
      min-width: 166px;
      @include m {
        min-width: 290px; } } } }

.services-ai-3 {
  &__cols {
    display: flex;
    align-items: flex-start;
    @include m {
      display: block; }
    &__col {
      width: 50%;
      @include m {
        width: auto; } } } }
