.main_ksa {
  background: linear-gradient(279.09deg, rgba(15, 105, 1, 0.66) 0%, rgba(1, 105, 56, 0) 100%), url('../../../img/main_ksa.png') no-repeat 50% 0% / cover;
  .main {
    &__inner {
      height: 576px;
      padding: 220px 32px 0;
      display: flex;
      align-items: flex-end;
      @include m {
        height: 1334px;
        padding: 572px 39px;
        display: block; } }
    &__title {
      @include fm;
      font-size: 80px;
      font-weight: 900;
      line-height: 1.2;
      color: $white;
      @include m {
        font-size: 120px; } } } }

