.page-section_business_missions {
  padding-top: 67px;
  padding-bottom: 66px;
  font-size: 16px;
  line-height: 1.2;
  @include m {
    font-size: 24px; }

  .page-section {
    &__cols-layout {
      display: flex;
      justify-content: space-between;
      @include m {
        display: block; } }
    &__col {
      &:not(:first-child) {
        padding-left: 15px;
        @include m {
          margin-top: 30px;
          padding-left: 0; } }
      &:not(:last-child) {
        padding-right: 15px;
        @include m {
          padding-right: 0; } }
      @include m {
        width: 100% !important; } }
    &__cols-layout_2 .page-section__col {
      width: 50%; }
    &__cols-layout_3 .page-section__col {
      width: 33.3%; }
    &__cols-layout_icons .page-section__col {
      display: flex;
      align-items: center; }
    &__cols-layout_icons .page-section__col > :first-child {
      margin-right: 36px;
      flex-shrink: 0; }

    &__caption {
      margin: 0 0 34px;
      @include fm;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: #EDCE8F;
      @include m {
        font-size: 30px;
        margin-bottom: 68px; } }

    &__hr {
      background: $white;
      height: 2px;
      margin: 60px 0 38px; }

    &__desc {
      margin: 0 0 50px; }
    &__advantages {
      margin: 0 0 61px; } } }
