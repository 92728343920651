.icons-text-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include m {
    display: block; }
  &__item {
    @include m {
      width: 100% !important;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 36px; } } }
  &__item > :first-child {
    @include m {
      flex-shrink: 0; } }
  &_cnum_3 &__item {
    width: 30%; }
  &_cnum_4 &__item {
    width: 24%; }
  &_cnum_3 &__item:nth-child(n+4),
  &_cnum_4 &__item:nth-child(n+5) {
    margin-top: 28px; }
  &__text {
    margin: 22px 0 0;
    font-size: 16px;
    line-height: 1.5;
    @include m {
      margin-top: 0;
      margin-left: 24px;
      font-size: 24px; } } }
