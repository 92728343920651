.footer {
  padding: 95px 0 90px;
  background: $blue;
  @include d {
    padding: 80px 0; }
  @include m {
    padding: 70px 0; }
  &__row {
    display: flex;
    margin: 0 -10px;
    padding: 0 98px;
    @include d {
      padding: 0 70px; }
    @include t {
      flex-direction: column-reverse;
      margin: 0;
      padding: 0 30px; }
    @include m {
      padding: 0; } }
  &__col {
    position: relative;
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 10px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:first-child {
      padding: 0 110px 0 10px;
      @include d {
        padding: 0; } }
    &:nth-child(2) {
      @include d {
        padding-left: 20px; }
      @include t {
        margin-bottom: 50px;
        padding: 0; }
      @include m {
        margin-bottom: 65px; } } }
  &__logo {
    position: absolute;
    top: 0;
    left: -98px;
    font-size: 30px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $white;
    @include d {
      left: -78px; }
    @include t {
      display: none; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 77px;
      width: 18px;
      height: 3px;
      margin-top: -2px;
      background: rgba($white, .1);
      @include d {
        display: none; } } }
  &__title {
    margin-bottom: 32px;
    font-size: 30px;
    line-height: 1.2;
    color: $white;
    @include m {
      margin-bottom: 55px;
      font-size: 40px; }
    br {
      @include t {
        display: none; }
      @include m {
        display: block; } } }
  &__list {
    margin-bottom: 45px;
    padding-bottom: 23px;
    border-bottom: 1px solid rgba($white, .1);
    @include m {
      margin-bottom: 35px;
      padding-bottom: 35px;
      border-width: 2px; } }
  &__line {
    display: flex;
    margin: 0 -10px;
    &:not(:last-child) {
      margin-bottom: 45px;
      @include m {
        margin-bottom: 80px; } } }
  &__cell {
    flex: 0 0 calc(33% - 20px);
    width: calc(33% - 20px);
    margin: 0 10px;
    &_contacts {
      flex-grow: 1;
      align-self: end; } }
  &__flag {
    margin-bottom: 14px;
    font-size: 0;
    @include m {
      margin-bottom: 20px; } }
  &__pic {
    width: 32px;
    @include m {
      width: 45px; } }
  &__place {
    font-size: 14px;
    line-height: (20/14);
    color: $white;
    @include m {
      font-size: 20px; }
    br {
      @include t {
        display: none; } } }
  &__link {
    font-size: 14px;
    line-height: (20/14);
    text-decoration: underline;
    color: #eecf8f;
    @include m {
      font-size: 20px; }
    &:hover {
      text-decoration: none; } }
  &__social {
    margin: 25px 0 0; }
  &__wrap {
    display: flex; }
  &__text {
    font-size: 14px;
    line-height: (20/14);
    color: $white;
    @include m {
      font-size: 20px; } }
  &__sign {
    margin: 0 7px;
    font-size: 14px;
    line-height: (20/14);
    color: #595959;
    @include m {
      margin: 0 12px;
      font-size: 20px; } }
  &__contact {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1.2;
    color: #eecf8f;
    @include m {
      margin-bottom: 35px; } }
  &__arrow {
    position: absolute;
    top: 5px;
    right: -98px;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    border: 2px solid rgba($white, .1);
    transition: all .3s;
    @include d {
      right: -78px; }
    @include t {
      right: 0; }
    @include m {
      display: none; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include arr(16, 8, #eecf8f, t); }
    &:hover {
      background: rgba($white, .1); } } }
