.marked-list.marked-list_style_simple {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  list-style: disc;
  @include m {
    font-size: 24px;
    line-height: 1.33; } }

.marked-list_style_simple {
  .marked-list {
    &__item {
      margin: 0 0 0 23px; } } }
