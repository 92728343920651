.services-scouting-1 {
  margin-top: 93px;
  margin-bottom: 81px;
  @include m {
    margin-top: 77px;
    margin-bottom: 74px; }
  &__title {
    color: #2a2d84;
    font-size: 20px;
    line-height: 35px;
    margin: 0 0 60px;
    @include m {
      margin-bottom: 48px;
      font-size: 30px;
      line-height: 45px; } }
  &__columns {
    display: flex;
    @include m {
      display: block; } }
  &__columns_2 &__col {
    width: 50%; }
  &__columns &__col {
    @include m {
      width: auto; } }
  &__h-list {
    border-top: 2px solid #e9eaeb;
    padding: 61px 0 0;
    @include m {
      padding-top: 47px;
      &:not(:first-child) {
        margin-top: 60px; } }
    &:not(:last-child) {
      padding-right: 20px; } }
  &__v-list {
    border-top: 2px solid #e9eaeb;
    padding: 61px 0 0;
    @include m {
      padding-top: 47px;
      &:not(:first-child) {
        margin-top: 60px; } }
    &:not(:last-child) {
      padding-right: 20px; }
    &_number {
      counter-reset: service; } }
  &__v-list_number &__icon:before {
    counter-increment: service;
    content: counter(service); }
  &__list-title {
    margin: 0 0 55px;
    color: #2a2d84;
    font-size: 20px;
    line-height: normal;
    @include m {
      margin-bottom: 33px;
      font-size: 30px;
      line-height: normal; } }
  &__item {
    color: #000;
    font-size: 16px;
    line-height: normal;
    min-height: 63px;
    display: flex;
    align-items: center; }
  &__item + &__item {
    margin-top: 15px;
    @include m {
      margin-top: 20px; } }
  &__icon {
    width: 63px;
    height: 63px;
    margin: 0 20px 0 0;
    background-color: #f8ecd2;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 63px;
    color: #232676;
    flex-shrink: 0;
    & img {
      display: inline-block;
      vertical-align: -6px; } }

  &__partner {
    border-top: 2px solid #e9eaeb;
    border-bottom: 2px solid #e9eaeb;
    margin: 70px 0 79px;
    padding: 51px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m {
      margin-top: 60px;
      margin-bottom: 72px;
      padding: 39px 0 44px; } }
  &__partner-text {
    font-size: 20px;
    line-height: normal;
    color: #2a2d84; }
  &__partner-logo {
    display: flex;
    align-items: center;
    img {
      display: block; }
    img + img {
      margin-left: 54px;
      @include m {
        margin-left: 31px; } } }

  &__make-order {
    @include m {
      text-align: center;
      .btn {
        min-width: 290px;
        box-sizing: border-box; } } } }
