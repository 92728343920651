.round-icon {
  background: 50% 50% no-repeat;
  border-radius: 50%;
  width: 63px;
  height: 63px;
  @include m {
    width: 126px;
    height: 126px; }
  &_bg_light {
    background-color: #f8ecd3; }

  &_img_book-blue {
    background-image: url("/img/icon-book-blue.png");
    background-size: 46%; }
  &_img_calendar-blue {
    background-image: url("/img/icon-calendar-blue.png");
    background-size: 46%; }
  &_img_camel-blue {
    background-image: url("/img/icon-camel-blue.svg");
    background-size: 62%; }
  &_img_chain-blue {
    background-image: url("/img/icon-chain-blue.png");
    background-size: 46%; }
  &_img_graph-blue {
    background-image: url("/img/icon-graph-blue.png");
    background-size: 46%; }
  &_img_handshake-blue {
    background-image: url("/img/icon-handshake-blue.svg");
    background-size: 59%; }
  &_img_knight-blue {
    background-image: url("/img/icon-knight-blue.png");
    background-size: 46%; }
  &_img_rocket-blue {
    background-image: url("/img/icon-rocket-blue.png");
    background-size: 46%; }
  &_img_star-blue {
    background-image: url("/img/icon-star-blue.png");
    background-size: 46%; }
  &_img_solutions-blue {
    background-image: url("/img/icon-solutions-blue.png");
    background-size: 46%; }
  &_img_tree-blue {
    background-image: url("/img/icon-tree-blue.png");
    background-size: 46%; }
  &_img_worldwide-blue {
    background-image: url("/img/icon-worldwide-blue.png");
    background-size: 46%; } }
