.company_main {
  .company {
    &__inner {
      padding-top: 80px;
      padding-bottom: 53px;
      @include d {
        padding-top: 75px;
        padding-bottom: 75px; } }
    &__preview {
      width: 445px;
      margin: 29px 0 0;
      float: right;
      @include t {
        width: auto;
        margin-bottom: 20px;
        float: none; }
      @include m {
        margin-bottom: 30px; } }
    &__list {
      margin: 10px 0;
      overflow: hidden; }
    &__item {
      float: left;
      clear: left; } } }
