.stat {
  padding: 95px 0 85px;
  background: #fcfcfc;
  @include d {
    padding: 80px 0; }
  &__row {
    display: flex;
    margin: 0 -10px;
    @include t {
      display: block;
      margin: 0; } }
  &__col {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 10px;
    @include t {
      flex: 0 0 100%;
      width: 100%;
      margin: 0 10px; }
    &:first-child {
      padding-right: 30px;
      @include t {
        margin-bottom: 60px;
        padding: 0; } } }
  &__title {
    margin-bottom: 41px;
    font-size: 30px;
    line-height: (45/30);
    color: $blue;
    @include t {
      margin-bottom: 30px; } }
  &__desc {
    margin-bottom: 10px;
    @include m {
      font-size: 20px;
      line-height: 1.5; } }
  &__text {
    position: relative;
    padding-left: 17px;
    font-size: 14px;
    line-height: (20/14);
    @include m {
      font-size: 18px;
      line-height: 1.5; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 2px;
      margin-top: -1px;
      background: $black; } }
  &__info {
    @include ft;
    font-size: 30px;
    line-height: (45/30); }
  &__item {
    padding: 23px 0;
    @include m {
      padding: 26px 0;
      font-size: 18px;
      line-height: (24/18); }
    &:not(:last-child) {
      border-bottom: 1px solid rgba($black, .1); } } }
