.form {
  &_pending {
    position: relative;
    &:before {
      content: '';
      background: rgba(255, 255, 255, 0.05);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2; }
    &:after {
      content: '';
      border: 4px solid #eecf8f;
      border-top-color: transparent;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3;
      animation: form-pending-rotating 2s linear infinite;
      @include m {
        border-width: 8px;
        width: 120px;
        height: 120px; } }
    & > * {
      filter: blur(1px); } }
  &_pending.form_bg_light:before {
    background: rgba(0, 0, 0, 0.05); }
  &__body {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 50px; } }
  &__line {
    display: flex;
    margin: 0 -15px 30px;
    @include m {
      display: block;
      margin: 0 0 35px; } }
  &__line &__field {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 0 15px;
    @include m {
      flex: 0 0 100%;
      width: 100%;
      margin: 0; }
    &:nth-child(1) {
      @include m {
        margin-bottom: 35px; } } } }

.field {
  &__input,
  &__textarea {
    width: 100%;
    background: none;
    @include fm;
    font-size: 14px;
    color: rgba($white, .7);
    transition: border-color .3s;
    @include m {
      font-size: 24px;
      line-height: 1; }
    @include placeholder {
      color: rgba($white, .3); } }
  &__input {
    height: 40px;
    border-bottom: 2px solid rgba($white, .1);
    &:disabled {
      opacity: 0.5; }
    &:focus {
      border-color: $yellow; }
    @include m {
      height: 50px; } }
  &__textarea {
    height: 140px;
    padding: 8px 0;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: rgba($white, .1);
    resize: none;
    &:disabled {
      opacity: 0.5; }
    &:focus {
      border-color: $yellow; } } }

.form_bg_light .field {
  &__input,
  &__textarea {
    border-color: rgba($black, .1);
    color: $black;
    @include placeholder {
      color: $black; }
    &:focus {
      border-color: $yellow; } } }

.radio, .checkbox {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  &__in {
    display: flex;
    align-items: center; }
  &__input:disabled + &__in {
    opacity: 0.5; }
  &__tick {
    position: relative;
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    border: 1px solid #eecf8f;
    background: $white;
    transition: border-color .3s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -4px 0 0 -4px;
      width: 8px;
      height: 8px;
      background: $blue;
      visibility: hidden;
      opacity: 0;
      transition: all .3s; } }
  &__text {
    font-size: 14px;
    color: rgba($black, .8);
    @include m {
      font-size: 20px; } }
  &__input:checked + &__in &__tick:before {
    visibility: visible;
    opacity: 1; } }

.radio {
  &__tick {
    border-radius: 50%;
    &:before {
      border-radius: 50%; } } }

@keyframes form-pending-rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@import "./_questionnaire/form_questionnaire.sass";
