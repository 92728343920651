.page-section {
  padding: 93px 0 70px;
  @include m {
    padding-top: 77px;
    padding-bottom: 85px; }

  &_white {
    background: $white; }
  &_blue {
    background: #2b3082;
    color: $white; }
  &_blue-dark {
    background: #1e205f;
    color: $white; }

  &__title {
    margin: 0 0 28px;
    @include ft;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2; }
  &_blue &__title,
  &_blue-dark &__title {
    color: #EDCE8F; } }

@import "./_ai/page-section_ai";
@import "./_business/page-section_business";
