.join {
  padding: 80px 0;
  background: url('../../img/bg-join.jpg') no-repeat 50% 50% / cover;
  @include t {
    padding: 60px 0; }
  @include m {
    padding: 80px 0; }
  &__center {
    padding: 0 0 73px;
    position: relative;
    display: flex;
    @include m {
      display: block;
      padding: 0; } }
  &__row {
    width: 50%;
    @include m {
      width: 100%;
      padding: 0 40px;
      text-align: center; } }
  &__row:first-child {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    @include m {
      padding: 0 40px; } }
  &__row + &__row:before {
    @include m {
      content: '';
      border-top: 1px solid #aa9273;
      display: block;
      height: 1px;
      margin: 40px; } }
  &__btn {
    position: absolute;
    bottom: 0;
    @include m {
      min-width: 290px;
      position: static; } }
  &__text {
    font-size: 16px;
    letter-spacing: -.35px;
    @include m {
      margin: 0 auto 40px;
      font-size: 20px;
      line-height: 1.2; } } }
