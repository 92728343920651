.marked-list.marked-list_style_rhombus {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  @include m {
    font-size: 24px;
    line-height: 1.33; } }

.marked-list_style_rhombus {
  .marked-list {
    &__item {
      padding: 0 0 0 33px;
      position: relative;
      @include m {
        padding-left: 80px; }
      &:before {
        content: '';
        border: 2px solid #EDCE8F;
        width: 13px;
        height: 13px;
        margin: auto 0;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        transform: rotate(-45deg);
        @include m {
          border-width: 4px;
          width: 26px;
          height: 26px; } } } }
  .marked-list__item + .marked-list__item {
    margin-top: 16px;
    @include m {
      margin-top: 30px; } } }
