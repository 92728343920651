.page_ksa {
  .header {
    .logo__pic {
      width: 201px;
      @include m {
        width: 290px; } }
    &__nav {
      top: 80px; } }
  .ksa__inner {
    padding-top: 12px; }
  .ksa__picture {
    top: -201px;
    @include d {
      top: -181px; }
    @include t {
      top: -151px; }
    @include m {
      margin-left: -440px;
      top: -542px; } }
  .ksa__img {
    @include m {
      width: 954px;
      height: 954px; } }
  .ksa__caption {
    margin-bottom: 124px;
    @include m {
      margin-top: -658px;
      margin-bottom: 742px;
      margin-left: -40px;
      font-size: 52px; } }
  .ksa__gth {
    margin-bottom: 91px; }
  .ksa__more {
    display: none; }

  .footer {
    background: #0b683a; } }
